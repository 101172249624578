import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store/index'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home/index.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login/index.vue')
  },
  {
    path: '/account',
    name: 'account',
    component: () => import('@/views/Account/index.vue'),
    children: [
      {
        path: '/account/order',
        name: 'order',
        component: () => import('@/views/Account/order.vue')
      },
      {
        path: '/account/details',
        name: 'details',
        component: () => import('@/views/Account/details.vue')
      },
      {
        // 路由重定向
        path: '/account',
        redirect: {
          path: '/account/order',
        }
      }
    ]
  },
  {
    path: '/products',
    name: 'products',
    component: () => import('@/views/Products/index.vue')
  },
  {
    path: '/productDetails',
    name: 'productDetails',
    component: () => import('@/views/ProductDetails/index.vue')
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import('@/views/Cart/index.vue')
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: () => import('@/views/Checkout/index.vue')
  },
  {
    path: '/pay',
    name: 'pay',
    component: () => import('@/views/Pay/index.vue')
  },
  {
    path: '/success',
    name: 'success',
    component: () => import('@/views/Pay/success.vue')
  },
  {
    path: '/failed',
    name: 'failed',
    component: () => import('@/views/Pay/failed.vue')
  },
  // 配置页 ______________________________________________________________________
  {
    path: '/about-us',
    name: 'about-us',
    component: () => import('@/views/Assist/about-us.vue')
  },
  {
    path: '/terms-conditions',
    name: 'terms-conditions',
    component: () => import('@/views/Assist/terms-conditions.vue')
  },
  {
    path: '/return-refund-policy',
    name: 'return-refund-policy',
    component: () => import('@/views/Assist/return-refund-policy.vue')
  },
  {
    path: '/payment-methods',
    name: 'payment-methods',
    component: () => import('@/views/Assist/payment-methods.vue')
  },
  {
    path: '/shipping-delivery',
    name: 'shipping-delivery',
    component: () => import('@/views/Assist/shipping-delivery.vue')
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import('@/views/Assist/privacy-policy.vue')
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    component: () => import('@/views/Assist/contact-us.vue')
  },
  {
    path: '/intellectual',
    name: 'intellectual',
    component: () => import('@/views/Assist/intellectual.vue')
  },
  // 404 页面
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: () => import('@/views/404.vue')
  },
  {
    // 路由重定向
    path: '/',
    redirect: {
      path: '/',
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // 每次路由跳转之后 新页面加载在x轴和y轴的初始位置
  scrollBehavior() {
    return {
      left: 0,
      top: 0,
    };
  },
})

// 全局前置守卫
router.beforeEach((to, from, next) => {

  // if (to.path === '/accomplish') {
  //   if (from.fullPath === '/checkout') {
  //     next(); // 正常跳转
  //   } else {
  //     next('/404')
  //   }
  // }

  if (to.path === '/checkout' && !store.state.userInfo.access_token) {
    next('/login');
  } else if (to.path === '/pay' && !store.state.userInfo.access_token) {
    next('/login');
  } else if (to.path === '/account/order' && !store.state.userInfo.access_token) {
    next('/login');
  } else {
    next(); // 正常跳转
  }



});

export default router
